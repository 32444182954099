@import '@theme/variables.scss';
main {
  .birthday-bonus-center {
    width: 95%;
    margin: auto;
    position: relative;
    padding-top: 3.438rem;

    hr {
      width: 85%;
      border-color: $blue-500;
      opacity: 0.5;
    }
    .download_icon,
    .birthday_search_icon,
    .birthday_gift_icon {
      width: 1.65rem;
    }
  }
  .birthday-content-img {
    position: absolute;
    top: -6.3rem;
    left: 0;
    right: 0;

    img {
      max-width: 9.375rem;
    }
  }
}
