@import '@theme/variables.scss';

.footer {
  background: $dark-gradient;
  padding: 0.313rem 0;
  position: relative;
  img {
    max-width: 4.938rem;
  }
  p {
    color: $white;
    padding-top: 0.625rem;
  }
}
