// @import "@theme/variables.scss";

@import '@theme/variables.scss';

.tool-tip-icon {
  width: $tooltip-icon-width;
  height: $tooltip-icon-height;
  margin-left: 0.313rem;
}

// Form label & Control start
main .form-label,
main .form-control-label {
  color: $primary;
  letter-spacing: $character-spacing-0-26;
  text-align: left;
  font-family: $font-family-poppins-medium;
  font-size: $font-size-xs;
  line-height: $line-height-xs;
  opacity: 1;
  margin-bottom: 0 !important;

  &.v1 {
    position: relative;
    bottom: -1.75rem;
    max-width: 50%;
  }
  & .input-group {
    max-width: 45%;
  }
}

main .form-control {
  border-style: none none solid none;
  border-width: 0 0 1 0 !important;
  border-color: $primary-light-100;
  border-radius: 0;
  text-align: $text-r;
  line-height: $input-line-height;
  padding-top: 0;
  font-size: $font-size-xs;
  font-family: $font-family-poppins-regular;
  color: $black;
  background: none;
  padding-left: $input-padding-left;
  padding-bottom: 0;
  padding-right: 0;
  letter-spacing: $character-spacing-0-42;

  &.v2 {
    padding: 0;
    text-align: $text-l;
    margin-top: 0.875rem;
    // color: $black-100;
  }

  &::placeholder {
    color: $grey-100;
    background: none;
  }

  &:focus {
    box-shadow: none;
    background: none;
  }

  &.is-valid {
    background-image: none !important;
    padding-right: 0;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  &.is-invalid {
    border-color: $error-light-100;
    background-image: none !important;
    padding-right: 0;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  &:disabled {
    background-color: transparent;
  }
}
input[type='date'] {
  &::placeholder {
    color: $grey-100;
    background: none;
  }
}
input[type='date'] {
  color: $grey-100;
}
input[type='date']:checked {
  color: $black;
}

.input-mb {
  &.relative {
    position: relative;
    .text-right {
      text-align: $text-r !important;
      padding-right: 22px !important;
    }
  }
  .input-group-text {
    z-index: 99;
    cursor: pointer;
  }
  .input-group-text {
    background: none;
    border: none;
    position: absolute;
    // right: 0;
    padding: 0;
    top: 30px;
    right: 5px;

    i {
      color: $primary;
    }
  }
}

.input-group {
  input[type='password'] {
    &:not(.v2) {
      padding-left: 150px;
    }
    padding-right: 1.875rem;
  }
  input[type='text'] {
    &:not(.v2) {
      padding-left: 150px;
    }
    padding-right: 1.875rem;
  }

  .input-group-text {
    z-index: 99;
    cursor: pointer;
  }

  .form-control.is-invalid,
  .form-control.is-valid {
    background-position: right calc(0.375em + 1.1rem) center;
  }

  .input-group-text {
    background: none;
    border: none;
    position: absolute;
    right: 0;
    padding: 0;
    top: 6px;
    right: 15px;

    i {
      color: $primary;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}
input:-internal-autofill-selected {
  background-color: transparent !important;
}
.text-danger {
  color: $danger;
  font-size: $font-10;
  font-family: $font-family-poppins-regular;
  letter-spacing: $character-spacing-0-3;
  opacity: 1;
  line-height: $line-height-l6;
  margin-top: 0.5rem;
}
.personal-data {
  .form-control {
    color: $black;
  }
}

.login-form {
  .form-control {
    color: $black;
  }
}


.personal-data {
  .form-control.is-valid {
    color: $success;
  }
  .form-control.is-invalid {
    color: $danger;
  }
}

.login-form {
  .form-control.is-valid {
    color: $success;
  }
  .form-control.is-invalid {
    color: $danger;
  }
}
