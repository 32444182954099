#loader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
  backdrop-filter: blur(3px);
}
