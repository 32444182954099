@import '@theme/variables.scss';

.tariff-details-option {
  .btn-link {
    height: auto;
  }
  .bi-check-lg {
    font-size: 1.15rem;
  }
}
.tariff-details {
  hr {
    border-color: $blue-500;
    opacity: 0.5;
  }
}
