@import '@theme/variables.scss';

main {
  .option-card {
    padding: 0.75rem 2rem;

    hr {
      border-color: $blue-500;
    }
    .options-badge {
      max-width: 10rem;

      h6 {
        font-family: $font-family-poppins-medium;
      }
    }
  }
  .w-45 {
    max-width: 45%;

    h6,
    span {
      line-height: 1.25rem;
      display: block;
    }
  }
  .w-35 {
    max-width: 35%;
  }
  .w-20 {
    max-width: 20%;

    .btn-link {
      text-transform: inherit;
      height: auto;
      .bi {
        color: $primary;
        font-size: 0.65rem;
      }
    }
  }
}
