@import '@theme/variables.scss';
.card-inner {
  width: 5.5rem;
  width: 5.625rem;
  height: 3.75rem;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  position: relative;

  &::before {
    background: url('../../../../../../theme/assets/img/add.svg') no-repeat 0 0;
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    bottom: -0.875rem;
    right: -0.875rem;
    left: auto;
  }

  &.selected {
    opacity: 1;
    background: $white;

    &::before {
      content: none;
    }
  }

  .card-body-inner {
    padding: 0 !important;
    opacity: 0.5;
  }
}
.paypal-icon {
  height: 3.75rem;

  img {
    max-width: 4rem;
  }
}
.payment-method {
  &:last-child {
    margin-right: 0 !important;
  }
}
