@import '@theme/variables.scss';

main .form-check-input[type='radio'] {
  display: block;
  width: $form-check-radio-width;
  height: $form-check-radio-height;
  pointer-events: none;
  background-color: $white;
  box-shadow: $box-shadow-sm;
  opacity: 1;

  &:checked {
    color: $white;
    background: $primary;
    border-color: $primary;
    background: url('../../../../theme/assets/img/white-round.svg') no-repeat 4px 4px,
      $dark-gradient !important;
    box-shadow: $box-shadow-sm;
    opacity: 1;
  }
  &:focus {
    box-shadow: none;
  }
}

main .form-check-label {
  color: $primary;
  text-align: left;
  font-size: $font-size-xs;
  letter-spacing: $character-spacing-0-24;
  opacity: 1;
  font-family: $font-family-poppins-regular;
  padding-left: $form-check-padding-start;
}
