@import '@theme/variables.scss';
.welcome-middle {
  height: calc(100vh - 116px);
  .title {
    h1 {
      text-transform: inherit;
    }
  }
  .btn-primary {
    margin-right: 1.5rem;
  }
}
