@import '@theme/variables.scss';

main .custom-switch {
  .form-check-input {
    width: $form-check-input-width;
    height: $form-check-input-height;
    border-radius: $form-check-input-border-radius;
    box-shadow: $form-check-input-focus-box-shadow;
    border: 0;
    background: $form-check-input-bg url('../../../../theme/assets/img/checked.svg') no-repeat -2px center !important;
    background-size: 1.75rem !important;
    cursor: pointer;

    &:checked {
      background: $form-check-input-bg-check url('../../../../theme/assets/img/checked.svg')
        no-repeat 14px center !important;
      background-size: 1.75rem !important;
      box-shadow: $form-check-input-focus-box-shadow-checked;
    }
    &:focus {
      background: url('../../../../theme/assets/img/checked.svg') no-repeat;
    }
  }

  .form-check-label {
    color: $primary;
    text-align: $text-l;
    font-size: $font-size-xs;
    letter-spacing: $character-spacing-0-24;
    opacity: 1;
    font-family: $font-family-poppins-regular;
    padding-top: 0.375rem;
    padding-left: 0.938rem;
    position: relative;
  }
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: $primary !important;
}
.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}
