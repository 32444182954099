@import '@theme/variables.scss';

// buttons
.btn {
  display: inline-block;
  font-weight: $font-weight-normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: $btn-padding-y $btn-padding-x;
  font-size: $font-size-xs;
  line-height: $line-height-l;
  border-radius: $btn-border-radius;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: $btn-height;
  font-family: $font-family-nexa-bold;
  letter-spacing: $character-spacing-1-2;
  text-transform: $text-uppercase;
}
.btn-primary {
  background: $dark-gradient;
  box-shadow: $btn-box-shadow;
  border-color: $btn-border-color;
  border: none;

  .bi-arrow-right {
    font-size: $font-size-base;
    vertical-align: top;
    position: relative;
    top: -2px;
    left: 5px;
  }

  &:hover {
    background: $dark-gradient;
    border: none;
    // color: $primary;
    // border-color: $primary;
  }
  &:focus,
  &:active {
    color: $white;
    background: $dark-gradient;
  }
}

.btn-secondary {
  background-color: $white;
  color: $primary;
  box-shadow: $secondary-btn-box-shadow;
  border-color: $white;

  &:hover {
    background-color: $white;
    color: $primary;
    border-color: $white;
  }
  &:active {
    box-shadow: none;
    border-color: $white !important;
    background-color: $white;
  }
  &:focus-visible {
    box-shadow: none;
    border-color: $white !important;
    background-color: $white;
  }
  &:focus,
  &:active {
    background-color: $white !important;
    color: $primary !important;
  }
}

.btn-danger {
  background: $danger !important;
  box-shadow: $danger-btn-box-shadow;

  &:hover {
    // background: $white !important;
    // color: $danger;
    // border-color: $danger;
  }

  &:focus,
  &:active {
    background: $danger !important;
    color: $white;
  }
  &:focus-visible {
    box-shadow: none;
    border: none;
  }
}
.btn-link {
  color: $primary;
  font-size: $font-size-sm;
  line-height: $line-height-19;
  font-family: $font-family-poppins-medium;
  text-transform: initial !important;

  &:focus-visible {
    box-shadow: none;
    color: $primary !important;
  }
}

.btn-success {
  background: $success !important;
  box-shadow: $btn-box-shadow;

  &:hover {
    // background: $white !important;
    // color: $danger;
    // border-color: $danger;
  }

  &:focus,
  &:active {
    background: $success !important;
    color: $white;
  }
  &:focus-visible {
    box-shadow: none;
    border: none;
  }
}
// Buttons Style End
