@import '@theme/variables.scss';

.warning-icon {
  img {
    width: 2.813rem;
  }
}
.download-icon img {
  width: 1rem;
}
.cursor-pointer {
  cursor: pointer;
}

.portability-heading {
  width: 241px;
}
