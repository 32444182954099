@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
.slick-list {
  padding: 0 !important;
}
.slick-slider {
  padding: 0;
  height: 20.313rem;
}
.slick-next {
  right: 30%;
  top: auto;
  bottom: -3.5rem;
  z-index: 9;
}
.slick-prev {
  left: 33%;
  top: auto;
  bottom: -3.5rem;
  z-index: 9;
}
.slick-dots {
  bottom: -2.563rem;
}
.slick-next,
.slick-next:hover,
.slick-next:focus {
  background: url('../../../theme/assets/img/arrow_right_small.svg') no-repeat;
}
.slick-prev,
.slick-prev:hover,
.slick-prev:focus {
  background: url('../../../theme/assets/img/arrow_left_small.svg') no-repeat;
}
.slick-prev:before,
.slick-next::before {
  content: none;
}
.slick-slide {
  min-height: 325px;
}
