@import '@theme/variables.scss';

main {
  .true-bonus-card {
    width: 100%;

    .true-bonus-bottom {
      > div {
        width: 33%;
        padding: 0 0.625rem;
      }
    }

    .truebonus-content-img {
      position: absolute;
      top: -4.5rem;
      left: 0;
      right: 0;

      img {
        max-width: 9.375rem;
      }
    }
  }

  .true-bonus-center {
    width: 90%;
    margin: auto;

    hr {
      border-color: $blue-500;
      opacity: 0.5;
    }
    .w-60 {
      width: 60%;
      margin: auto;
    }

    &.truebonus-content-main {
      padding-top: 4.5rem;
      width: 80%;
    }
  }
}
