@import '@theme/variables.scss';

.book-pass-fail,
.book-pass-success {
  width: $modal-width;

  p {
    color: $primary !important;
  }
  .btn-link {
    padding-top: 1.5rem !important;
  }
}
.offer-price {
  h6 {
    font-size: $font-30;
    font-family: $font-family-poppins-medium;
    color: $primary;
  }
  span {
    font-size: $font-size-xs;
    font-family: $font-family-poppins-regular;
    line-height: $line-height-19;
    color: $primary;
  }
}
