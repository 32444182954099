@import '@theme/variables.scss';

.help-legal {
  .btn-link {
    text-align: $text-l;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;

    span {
      display: flex;
      align-items: center;
      img {
        max-width: 1.25rem;
        max-height: 1.25rem;
        margin-right: 0.75rem;
      }
    }
  }
}
