@import '@theme/variables.scss';

.auto-topup {
  hr {
    border-color: $blue-500;
    opacity: 0.5;
  }

  .plus-icon {
    width: 1.625rem;
    height: 1.625rem;
    background: $white;
    border-radius: 100%;
    box-shadow: $btn-box-shadow;
    display: flex;
    justify-content: center;
    align-items: center;

    .bi {
      font-size: $font-18;
      position: relative;
      top: 0.1rem;
    }
  }
  .charges-text {
    position: relative;
    top: 0.188rem;
  }

  .error-icon {
    width: 0.625rem;
    margin-right: 0.313rem;
    vertical-align: middle;
    padding-top: 0.188rem;
  }
}
