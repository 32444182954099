// poppins font kit
@font-face {
  font-family: 'poppinsmedium';
  src: url('./fonts/poppins-medium.woff2') format('woff2'),
    url('./fonts/Poppins-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'poppinsregular';
  src: url('./fonts/poppins-regular.woff2') format('woff2'),
    url('./fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'poppinssemibold';
  src: url('./fonts/poppins-semibold.woff2') format('woff2'),
    url('./fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
// nexa xbold font kit
@font-face {
  font-family: 'NexaW01-XBold';
  src: url('./fonts/NexaW01-XBold.eot');
  src: url('./fonts/NexaW01-XBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/NexaW01-XBold.woff2') format('woff2'),
    url('./fonts/NexaW01-XBold.woff') format('woff'),
    url('./fonts/NexaW01-XBold.ttf') format('truetype'),
    url('./fonts/NexaW01-XBold.svg#NexaW01-XBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
