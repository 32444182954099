@import '@theme/variables.scss';
main a {
  color: $primary;
  font-family: $font-family-poppins-medium;
  font-size: 0.813rem;
  letter-spacing: $character-spacing-0-3;
  line-height: $line-height-xs;

  &:focus-visible {
    outline: none;
  }

  &:hover {
    color: $primary;
  }
}

.link-light-blue {
  color: $blue-300;

  &:hover {
    color: $white;
  }
}
.fussnote-link {
  font-family: $font-family-poppins-medium;
  font-size: $font-size-xs;
  color: $primary;
  letter-spacing: $character-spacing-0-26;
  line-height: $line-height-19;
  text-decoration: $text-underline;
}
