@import '@theme/variables.scss';

.welcome-badge {
  max-width: 10.6rem;
  background: $white;
  padding: 6px 15px;
  border-radius: 2rem;
}

.modal-body {
  &.auto-topup {
    text-align: left !important;
    p{
      color: $blue !important;
    }
  }
}