@import '@theme/variables.scss';

.modal {
  top: auto;
  right: auto;
  left: auto;
  bottom: auto;
  z-index: 1;
  background: $primary-light-gradient-modal;

  .modal-dialog.modal-fullscreen {
    background: $primary-light-gradient-modal;

    .modal-content {
      max-width: 100% !important;
      box-shadow: none !important;
      background: none !important;
      margin-top: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;

      .emailmodal {
        .successicon {
          width: $email-success-icon-width;
          height: $email-success-icon-height;
        }

        .modal-body {
          padding-bottom: 0;
        }

        .vh-30 {
          min-height: calc(100vh - 0px);
        }

        .email-text {
          p {
            color: $blue;
            font-size: 20px;
            letter-spacing: 1.6px;
            font-weight: $font-weight-bold;
            line-height: 26px;
          }
        }
      }
    }
  }

  .modal-dialog {
    .modal-content {
      max-width: $modal-width;
      background: $bs-gradient !important;
      box-shadow: 0px 8px 20px #7ebbe6;
      border-radius: $card-border-radius;
      opacity: 1;
      margin-top: $modal-top-margin;
    }

    .close {
      background: url('../../../../theme/assets/img/close_popup.svg') no-repeat center center;
      width: 1.5rem;
      height: 1.5rem;
    }

    .modal-content {
      .modal-image {
        border-radius: $card-image-radius $card-image-radius 0 0;
        // width: 100%;
        // height: 223px;
      }
      // border: 0;
      // background: none;
      // padding: 2.5rem 1.875rem;

      .modal-main-content {
        border: 0;
        background: none;
        padding: 2.5rem 1.875rem;
      }

      .modal-header {
        border: 0;
        padding: 0 0 2.5rem 0;
        display: block;

        &.header-padding {
          padding: 0;
        }

        .modal-title {
          text-align: $text-c;
          color: $white;
          letter-spacing: $character-spacing-0-3;
          font-size: $h3-font-size;
          font-family: $font-family-poppins-semibold;
          line-height: $line-height-sm;

          &.tariff-alert-heading {
            width: 192px;
          }
        }

        .close {
          border: none;
          position: absolute;
          right: 1.25rem;
          top: 1.25rem;
          border-radius: 100%;
        }
      }

      .modal-body {
        padding: 0;
        text-align: $text-c;

        p {
          color: $white;
          font-family: $font-family-poppins-regular;
          margin: 0;
          padding: 1.875rem 0;
          padding-top: 0;
        }
      }

      .modal-footer {
        border: 0;
        padding: 1.875rem 0 0 0;
        display: block;
        text-align: $text-c;
      }
    }
  }

  &.overlay-dashboard {
    .modal-content {
      // max-width: 18.75rem;
      // margin-top: 3.5rem;

      .btn.btn-link.p-0 {
        text-decoration: none;
        font-family: $font-family-poppins-semibold;
        position: absolute;
        right: 0;
        left: 0;
        z-index: 999;
        font-size: $font-size-xs;
        bottom: -4rem;
      }
    }
  }

  &.overlay-mobile {
    .modal-content {
      max-width: 18.75rem;
      margin-top: 3.5rem;

      .btn.btn-link.p-0 {
        text-decoration: none;
        font-family: $font-family-poppins-semibold;
        position: absolute;
        right: 0;
        left: 0;
        z-index: 999;
        font-size: $font-size-xs;
        bottom: -4rem;

        img {
          max-width: 1.25rem;
          margin-left: 0.5rem;
        }
      }

      .modal-header {
        padding: 0 0 0 0;
      }

      .app-main-img {
        img {
          width: auto !important;
        }
      }

      .nav .nav-item img {
        max-width: 5.625rem;
      }
    }

    // .logout-icon-bottom
    // {

    //   .btn
    //   {
    //     text-decoration: none;
    //     font-family: $font-family-poppins-semibold;
    //   }
    //   img
    //   {
    //     max-width: 1.5rem;
    //     margin-left: 0.5rem;
    //   }
    // }
  }

  &.overlay-ipad {
    .modal-content {
      margin-top: 3.75rem;

      .modal-header {
        padding: 0 0 0 0;

        .modal-title {
          font-family: $font-family-nexa-bold !important;
          padding-bottom: 1.25rem;
        }
      }

      .btn.btn-link.p-0 {
        text-decoration: none;
        font-family: $font-family-poppins-semibold;
        position: fixed;
        right: 2.5rem;
        z-index: 999;
        font-size: $font-size-xs;
        top: 0.625rem;

        img {
          max-width: 1.25rem;
          margin-left: 0.5rem;
        }
      }
    }

    .app-main-img {
      img {
        width: auto !important;
      }
    }

    .logout-icon-top {
      .ipad-app-text {
        padding-top: 1rem;
      }

      .warning-icon {
        text-align: $text-c;

        img {
          max-width: 3.25rem !important;
        }
      }
    }
  }
}

.explainer-video {
  img {
    border-radius: 0.625rem;
    cursor: pointer;
  }
}
