@import '@theme/variables.scss';

.contact-phone {
  p {
    u {
      display: block;
    }
    margin-bottom: 0;
  }
  .contact-phone-icon {
    img {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
.service-contact {
  hr {
    border-color: $blue-500;
    opacity: 0.5;
  }
}
