@import '@theme/variables.scss';

.auto-topup {
  .custom-dropdown {
    min-width: 5rem;
  }
  .set-auto-opt2 {
    position: relative;
    left: 10px;
  }
  .set-auto-pricing {
    .form-check-label {
      padding-top: 0.375rem;
    }
  }

  .number-label {
    display: block;
    width: 192px !important;
  }

  .btn-primary {
    margin-right: 0 !important;
  }
  .auto-top-radio-option {
    span.text-danger {
      position: absolute;
      bottom: -1.563rem;
      width: 100%;
      left: 0;
    }
    .col-lg-4,
    .col-lg-3 {
      position: relative;
    }
  }
  .form-check {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .form-check-label {
      padding-top: 0.188rem;
    }

    .form-check-input[type='radio'] {
      min-width: 1.375rem;
    }
  }
  .auto-topup-head {
    width: 257px;
  }
}
