@import '@theme/variables.scss';

.recharge-credit {
  .welcome-badge {
    max-width: 11rem;
  }
  .btn-link {
    font-size: $font-size-xs;
  }
  .charging-tool-tip {
    margin-bottom: 7px;
  }
}
