.volume-progress {
  position: relative;
  margin: 0px 15px;
  padding-bottom: 40px;
}
.volume-counters {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
}
.initial-value {
  padding: 0 5px;
  flex-grow: 1;
  flex-basis: 0;
}
.final-value {
  padding: 0 5px;
  flex-grow: 1;
  text-align: right;
  flex-basis: 0;
}

.big-bar-outer-layer {
  position: relative;
  overflow: hidden;
  height: 230px;
  margin-bottom: -14px;
}
.big-bar {
  position: absolute;
  top: 0;
  left: 0;
  rotate: 45deg;
  border-radius: 50%;
  box-sizing: border-box;
  border: 25px solid #eee;
  border-bottom-color: rgb(255, 162, 0);
  border-right-color: rgb(255, 162, 0);
}

@media only screen and (min-width: 0px) and (max-width: 575.98px) {
  .big-bar-outer-layer {
    width: 250px;
    height: 115px;
  }
  .big-bar {
    width: 245px;
    height: 245px;
    border: 15px solid #eee;
    border-bottom-color: rgb(255, 162, 0);
    border-right-color: rgb(255, 162, 0);
  }
}

@media only screen and (min-width: 576px) and (max-width: 991.98px) {
  .big-bar-outer-layer {
    width: 350px;
    height: 165px;
  }
  .big-bar {
    width: 345px;
    height: 345px;
    border: 20px solid #eee;
    border-bottom-color: rgb(255, 162, 0);
    border-right-color: rgb(255, 162, 0);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .big-bar-outer-layer {
    width: 410px;
    height: 170px;
  }
  .big-bar {
    width: 410px;
    height: 410px;
    border: 25px solid #eee;
    border-bottom-color: rgb(255, 162, 0);
    border-right-color: rgb(255, 162, 0);
  }
}

@media only screen and (min-width: 1200px) {
  .big-bar-outer-layer {
    width: 500px;
    height: 230px;
  }
  .big-bar {
    width: 490px;
    height: 490px;
    border-bottom-color: rgb(255, 162, 0);
    border-right-color: rgb(255, 162, 0);
  }
}
