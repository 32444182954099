@import '@theme/variables.scss';

.activation-slide-align {
  padding-left: 5.9rem !important;

  h4 {
    font-size: $font-18;
    font-weight: $font-weight-bold;
    letter-spacing: $character-spacing-0-4;
    text-transform: inherit !important;
  }
}

.listing-badge {
  max-width: 11.25rem;

  h6 {
    font-family: $font-family-poppins-regular;
    line-height: $line-height-19;
    font-weight: $font-weight-bolder;
    font-size: $font-10;
    letter-spacing: $character-spacing-0-2;
    color: $primary;
  }

  img {
    max-width: 1.375rem;
  }
}

.slide-button-end {
  padding-left: 26rem;
}

.product-info {
  padding-left: 12.25rem;

  h6 {
    u {
      font-size: $font-size-xs;
      line-height: $line-height-l6;
      color: $primary;
      cursor: pointer;
    }
  }
}

.activation-slide-align-details {
  padding-left: 13rem !important;
}

.choose-tarrif-center {
  > div {
    &:last-child {
      margin: 0 !important;
    }
  }
}

.talk-start-tariff {
  padding-left: 17rem;

  li {
    width: 16.9rem;
  }
}
