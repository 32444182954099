@import '@theme/variables.scss';
.aufladung-first {
  padding: 0.625rem 0.75rem;
  border-radius: 0.625rem;
  height: 56px;

  > div {
    p {
      line-height: 1rem;
    }
    span {
      color: $white;
      opacity: 0.6;
      font-size: 0.625rem !important;
    }
  }
}
