// @import "@theme/variables.scss";

@import '@theme/variables.scss';

// @import "./typography.scss";
// @import "./mixins";

.activation-middle {
  h5 {
    font-size: $font-size-lg;
    color: $white;
    font-family: $font-family-nexa-bold;
    text-transform: $text-uppercase;
    line-height: $line-height-30;
    letter-spacing: $character-spacing-1-9;
    margin-bottom: 0;
  }
  p {
    font-size: $font-size-md;
    line-height: $line-height-xs;
    letter-spacing: $character-spacing-0-28;
    font-family: $font-family-poppins-semibold;
    color: $white;
  }
}
.welcome-steps {
  min-width: 42.5rem;
  p {
    font-family: $font-family-poppins-regular;
  }
  span {
    font-size: $font-22;
    color: $blue-300;
    font-family: $font-family-poppins-semibold;
    letter-spacing: $character-spacing-0-44;
    line-height: $line-height-sm;
  }
}
.welcome-bg {
  background-position: right bottom !important;
  background-repeat: no-repeat !important;
  // background-size: 25rem 0;
}
