@import '@theme/variables.scss';
.bs-stepper-header {
  display: block !important;
}
.stepper {
  .stepper-title-count {
    display: flex;
    justify-content: space-between;

    p {
      margin-bottom: 0;
      text-align: $text-l;
      font-size: $font-size-md;
      line-height: $line-height-md;
      font-family: $font-family-nexa-bold;
      letter-spacing: $character-spacing-1-4;
      color: $primary;
      text-transform: $text-uppercase;
    }
    span {
      font-family: $font-family-nexa-bold;
      line-height: $line-height-md;
      font-size: $font-size-xs;
      color: $primary;
      letter-spacing: $character-spacing-0-96;
    }
  }
  .stepper-progress {
    ul {
      padding-left: 0;
      line-height: $line-height-l;
      li {
        display: inline-block;
        &.stepper-bg {
          border-radius: $stepper-radius;
          background: $white;
          width: $stepper-w;
          height: $stepper-h;
          margin-right: $stepper-mr;

          &:last-child {
            margin-right: 0;
          }

          &.active,
          &.completed {
            background: $primary;
          }
        }
      }
    }
  }
}
.back-icon {
  padding: 1.25rem 0;
}
.card-activation-select {
  border-radius: 1.438rem;
  background: $white;
  padding: 5px 10px;
  p {
    font-size: $font-11;
    margin-bottom: 0;
    span {
      font-family: $font-family-poppins-medium;
    }
  }
}
