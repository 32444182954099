@import '@theme/variables.scss';

.auto-top-amount {
  .form-check {
    margin-bottom: 0;
  }

  .direct-input {
    min-height: 37px;
  }
}
