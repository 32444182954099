@import '@theme/variables.scss';

.ek-colors {
  ul {
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin: 0 10px;
    }
  }
}

.ek-primary-color {
  background: $primary;
  width: 125px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.ek-primary-light-color {
  background: $primary-light;
  width: 125px;
  height: 100px;
  color: $white;
  padding: 10px;
}

// secondary color
.ek-talk {
  background: $cyan;
  width: 100px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.ek-Kombi-xl {
  background: $grey-600;
  width: 100px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.ek-Kombi-s {
  background: $red-100;
  width: 100px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.ek-Kombi-max {
  background: $grey-500;
  width: 100px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.ek-Kombi-m {
  background: $grey-400;
  width: 100px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.ek-KomJahrespaket-start {
  background: $grey-200;
  width: 100px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.ek-Kombi-l {
  background: $grey-300;
  width: 100px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.ek-KJahrespaket-premium {
  background: $yellow-300;
  width: 100px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.ek-Success {
  background: $success;
  width: 100px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.ek-caution {
  background: $warning;
  width: 100px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.ek-error {
  background: $danger;
  width: 100px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.ek-gradients {
  ul {
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin: 0 10px;
    }
  }
}

.ek-gradient {
  background: $dark-gradient;
  width: 100px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.ek-light-gradient {
  background: $light-gradient;
  width: 100px;
  height: 100px;
  color: $white;
  padding: 10px;
}
.fussnote {
  font-family: $font-family-poppins-regular;
  font-size: $font-size-xs;
  color: $primary;
  letter-spacing: $character-spacing-0-26;
  line-height: $line-height-19;
}
.breadcum {
  font-family: $font-family-poppins-regular;
  font-size: $font-9;
  color: $primary;
  letter-spacing: $character-spacing-0-26;
  line-height: $line-height-19;
}
