@import '@theme/variables.scss';

.activation-main {
  .not-editable {
    color: $grey-100;
  }
  .editable {
    color: #000000;
  }
}
