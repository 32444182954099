@import '@theme/variables.scss';

.tariff-banner {
  // position: absolute;
  height: 33px;
  width: 100%;
  left: 0;
  top: 143px;
  background: $light-yellow;

  p {
    padding: 0.4rem 0;
  }
}
