@import '@theme/variables.scss';

.btn-details {
  .btn {
    display: flex;
    margin: 1.25rem auto 0;
    height: auto;
    align-items: center;
    text-transform: inherit;

    .plus-icon {
      width: 1.625rem;
      height: 1.625rem;
      background: $white;
      border-radius: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      .bi {
        font-size: $font-18;
        position: relative;
        top: 1px;
      }
    }
  }
}

.circlular-progress-inner {
  max-width: 8.25rem;
  max-height: 8.25rem;

  p {
    margin-bottom: 5px;
  }

  h6 {
    line-height: $line-height-md;
    letter-spacing: 0.46px;
    color: $primary;
    font-size: $font-23;
    letter-spacing: $character-spacing-0-4;
    margin-bottom: 0.313rem;
  }

  .details-expiry {
    color: $primary;
    font-size: $font-13;
    letter-spacing: $character-spacing-0-2;
    width: 129px;
  }

  .unlimited-heading {
    font-size: $font-19;
    // letter-spacing: $character-spacing-0-2;
    color: $primary;
    // margin-bottom: 0.313rem;
  }

  .unlimited-img {
    width: 2rem;
    height: 1rem;
  }
}

.CircularProgressbar {
  &.unlimited-card {
    background-color: $blue-300;
  }
}

.font-blue {
  color: $primary;
}
