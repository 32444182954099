@import '@theme/variables.scss';

.tooltip {
  max-width: $tooltip-width;
  text-align: $text-l;
}
.tooltip-inner {
  height: auto !important;
  font-size: $tooltip-font-size;
  line-height: $tooltip-line-height;
  font-family: $font-family-poppins-regular;
  letter-spacing: $tooltip-letter-spacing;
  color: $tooltip-color;
  opacity: 1;
  max-width: $tooltip-width;
  min-width: $tooltip-min-width;
  min-height: $tooltip-min-height;
  padding: $tooltip-padding-y $tooltip-padding-x !important;
  text-align: $text-l !important;
  p {
    font-size: $tooltip-font-size;
    line-height: $tooltip-line-height;
    font-family: $font-family-poppins-regular;
    letter-spacing: $tooltip-letter-spacing;
    color: $tooltip-color;
    margin-bottom: 0;
    text-align: $text-l;
  }
}
