@import '@theme/variables.scss';

.tarif-options-list {
  ul {
    padding: 0;

    li {
      list-style: none;
      margin-left: -0.313rem;
    }
  }
  .btn {
    text-transform: inherit;
  }

  hr {
    border-color: $blue-500;
  }
}
