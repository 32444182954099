@import '@theme/variables.scss';

main {
  .auto-card {
    width: 100%;

    hr {
      border-color: $blue-500;
      opacity: 0.5;
    }
    .w-60 {
      width: 60%;
      margin: auto;
    }
  }
  .auto-top-center {
    text-align: $text-c;
    width: 80%;
    margin: 0.5rem auto 0;
    padding-top: 6.5rem;
    position: relative;

    .auto-topup-content-img {
      top: -6.5rem;
      position: absolute;
      left: 0;
      right: 0;

      img {
        max-width: 10rem;
      }
    }
  }
}
