@import '@theme/variables.scss';

main .forget-password-modal {
  max-width: $modal-width;
  margin: auto;
  p {
    color: $primary !important;
    padding: 0.75rem !important;
    margin-bottom: 0;
  }
}
