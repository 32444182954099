@import '@theme/variables.scss';

//NavTabs
.nav {
  &.nav-tabs {
    .nav-item {
      .nav-link {
        color: $primary !important;
        text-align: $nav-text-align;
        font-family: $nav-font-family;
        font-size: $nav-link-font-size;
        line-height: $nav-line-height;
        letter-spacing: $nav-letter-spacing;
        font-weight: $nav-link-font-weight;
        margin-bottom: 0;
        border: 0;
        opacity: 1;
        padding: $nav-link-padding-y $nav-link-padding-x;

        &.active {
          font-family: $nav-tabs-link-active-font-family;
          letter-spacing: $character-spacing-0-4;
          border-bottom: 2px solid $nav-tabs-link-active-border-color;
          margin-bottom: $nav-link-active-margin-y;
          font-size: $nav-tabs-link-active-font-size;
          background: transparent;
        }
      }
    }
  }
}
