@import '@theme/variables.scss';

.set-direct-topup {
  .btn-primary {
    margin-right: 0 !important;
  }

  .direct-topup-head {
    width: 257px;
  }

  .set-direct-pricing {
    .form-check-label {
      padding-top: 0.375rem;
    }
  }

  .number-label {
    display: block;
    width: 192px !important;
  }
}
