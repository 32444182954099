@import '@theme/variables.scss';

.logout-alert {
  .logout-img {
    width: 3.7rem !important;
    height: 2.7rem !important;
  }

  .btn-link {
    color: $blue-300;
  }
}

.logout-modal {
  max-height: 336px;
}
