@import '@theme/variables.scss';

.user-account {
  width: 7.5rem;
  min-height: 7.5rem;
  margin: 1rem 1rem;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  border: $card-border;
  backdrop-filter: blur(10px);
  background: $card-box-gradient;
  opacity: 1;
  position: relative;

  h6 {
    color: $primary;
    font-size: $font-size-xs;
    line-height: $line-height-l8;
    font-family: $font-family-poppins-medium;
    letter-spacing: $character-spacing-0-24;
    margin-top: 0.75rem;
    margin-bottom: 0;
  }

  img {
    max-height: 2.5rem;
  }

  &.blurred {
    filter: blur(3px);
  }
}

.user-account-main {
  width: 32.5rem !important;
  margin: 1.5rem auto 0;
}
