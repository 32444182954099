@import '@theme/variables.scss';

.login-top-title {
  h4 {
    width: 100%;
  }
  .arrow-left {
    position: absolute;
    left: 1.25rem;
    top: 2.125rem;
    cursor: pointer;
  }
}
.login-form {
  padding-top: 3.75rem;

  .input-group {
    .input-group-text {
      top: 1.1rem;
    }
  }
  .input-mb {
    margin-bottom: 2rem !important;
  }
}

.forgot-modal {
  p {
    padding-bottom: 0 !important;
  }
  ul {
    padding: 0;
    margin-top: 0.2rem;
    li {
      list-style: none;
      color: $white;
      position: relative;
      font-size: $font-size-sm;
      letter-spacing: $character-spacing-0-26;
      line-height: $line-height-xs;
      font-family: $font-family-poppins-regular;

      &::before {
        position: absolute;
        content: '';
        left: 0;
        width: 0.25rem;
        height: 0.25rem;
        top: 0.6rem;
        left: auto;
        background: $white;
        border-radius: 100%;
        margin-left: -0.75rem;
      }
    }
  }
  .btn-link {
    color: $blue-300;

    &:hover {
      color: $white;
    }
  }
}
