@import '@theme/variables.scss';

.status-ribbon {
  position: absolute;
  z-index: 1;
  rotate: 355deg;
  width: inherit;
  top: 2.5rem;
  right: 0px;
}

main .card.tarrif-listing {
  max-width: 16.688rem;
  width: 16.688rem;
  background-position: right bottom;
  background-repeat: no-repeat;

  .card-body {
    padding: 1.25rem !important;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 55%;
    border-radius: 1.25rem;

    ul {
      li {
        margin-bottom: 0.75rem;
      }
    }

    .card-title {
      padding-bottom: 0.938rem;
      font-size: $font-18;
    }

    .tarrif-total {
      margin-top: 0.75rem;
    }
  }

  &.talk-small {
    background-size: 100%;
    margin-top: 7.3rem;

    .card-body {
      background-size: 55%;
    }

    .card-title {
      font-size: $font-size-sm;
      line-height: $line-height-11;
      padding-bottom: 0.75rem;
    }

    .card-body {
      padding: 0.95rem;

      ul {
        li {
          font-size: 9px;
          line-height: 11px;
        }
      }
    }

    .tarrif-total {
      margin-top: 0.5rem;
    }
  }

  &.tarrif-detail {
    width: $modal-width;
    max-width: $modal-width;
    margin: auto;

    .card-body {
      padding: 1.875rem !important;

      ul {
        li {
          margin-bottom: 1rem;
        }
      }
    }

    .tarrif-total {
      margin: 1.5rem 0;
    }

    h2.card-title {
      text-align: $text-l !important;
      padding-bottom: 1.857rem !important;
    }
  }

  &.tariff-options-slide {
    width: 13.5rem;
    margin: 2.75rem auto 0;

    .card-body {
      padding: 1rem !important;
      background-size: 50%;

      ul {
        li {
          font-size: $font-size-xs;
          font-family: $font-family-poppins-regular;

          .bi-chevron-right {
            padding-top: 0.125rem;
          }
        }
      }

      .tarrif-total {
        margin-top: 0.25rem;

        p {
          font-size: $font-size-xs;

          span {
            font-size: $h4-font-size;
          }
        }
      }

      .card-title {
        padding-bottom: 0.75rem;
        font-size: $h4-font-size;
        border-bottom: 0;
      }
    }
  }
}

.listing-max-width {
  max-width: 16.688rem;
  width: 16.688rem;
}

.listing-badge {
  .listing-max-width {
    max-width: 16.688rem;
    width: 16.688rem;
  }

  .listing-badge {
    max-width: 10rem;
    align-items: center;
  }
}

main .slick-active.slick-current {
  width: 17.188rem !important;

  .tariff-options-slide {
    width: 17.188rem !important;
    margin-top: 1.75rem !important;
    height: 17.188rem !important;

    .card-title {
      font-size: $font-18 !important;
    }

    .card-body {
      // padding: 1.25rem !important;
      background-size: 65%;

      + ul {
        padding-bottom: 0.5rem;

        li {
          font-size: $font-size-sm;
        }
      }

      .tarrif-total {
        margin-top: 0.75rem;

        p {
          font-size: $font-size-sm;

          span {
            font-size: $h2-font-size;
          }
        }
      }

      .active-plan-ribbon {
        height: 1.625rem;

        span {
          padding: 0.3rem 0;
          font-size: $font-size-sm;
        }
      }
    }
  }
}

.slick-slider {
  padding: 0 !important;
  margin-left: -3px;
}

.slick-dots li {
  margin: 0;
}

.slick-dots li button:before {
  color: $primary;
  opacity: 0.4;
}

.slick-dots li.slick-active button:before {
  color: $primary;
  opacity: 1;
}

.active-plan-ribbon {
  height: 1.25rem;

  span {
    position: absolute;
    left: 0;
    right: 0;
    color: $white;
    font-size: $font-10;
    letter-spacing: $character-spacing-0-4;
    line-height: $line-height-l6;
    padding: 0.125rem 0;
  }
}
