.small-volume-progress {
  padding-bottom: 15px;
}
.small-volume-counters {
  position: relative;
  bottom: 8px;
}
.sms-counter-detail {
  float: right;
  clear: both;
}

.small-bar-outer-layer {
  position: relative;
  overflow: hidden;
  height: 130px;
  margin-bottom: -14px;
}
.small-bar {
  position: absolute;
  top: 0;
  left: 0;
  rotate: 45deg;
  border-radius: 50%;
  box-sizing: border-box;
  border: 20px solid #eee;
  border-bottom-color: rgb(255, 162, 0);
  border-right-color: rgb(255, 162, 0);
}

@media only screen and (min-width: 0px) and (max-width: 575.98px) {
  .small-bar-outer-layer {
    width: 250px;
    height: 115px;
  }
  .small-bar {
    width: 245px;
    height: 245px;
    border: 15px solid #eee;
    border-bottom-color: rgb(255, 162, 0);
    border-right-color: rgb(255, 162, 0);
  }
}

@media only screen and (min-width: 576px) and (max-width: 991.98px) {
  .small-bar-outer-layer {
    width: 200px;
    height: 90px;
  }
  .small-bar {
    width: 200px;
    height: 200px;
    border-bottom-color: rgb(255, 162, 0);
    border-right-color: rgb(255, 162, 0);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .small-bar-outer-layer {
    width: 200px;
    height: 90px;
  }
  .small-bar {
    width: 200px;
    height: 200px;
    border-bottom-color: rgb(255, 162, 0);
    border-right-color: rgb(255, 162, 0);
  }
}

@media only screen and (min-width: 1200px) {
  .small-bar-outer-layer {
    width: 200px;
    height: 90px;
  }
  .small-bar {
    width: 200px;
    height: 200px;
    border-bottom-color: rgb(255, 162, 0);
    border-right-color: rgb(255, 162, 0);
  }
}
