@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '@theme/variables.scss';

.tarif-data-slide {
  height: 18.75rem;
  background: transparent linear-gradient(162deg, #ffffffcc 0%, #ffffff33 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 3px 9px #7090b0;
  border-radius: 1.375rem;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  padding: 0.938rem;

  .circularprogress {
    width: 10rem;
    height: 10rem;
    margin: auto;
  }

  h4 {
    border-bottom: 1px solid $blue-500;
  }
}

.dashboard-banner {
  // position: absolute;
  height: 33px;
  width: 100%;
  left: 0;

  // top: 143px;
  background: $light-yellow;

  p {
    padding: 0.4rem 0;
  }
}

.dashboard-slider {
  .slick-slide {
    max-width: 14.3rem;
    margin: 0 0.81rem;
    padding-bottom: 1rem;
    padding-top: 0.5rem;
  }

  .slick-slider {
    margin-left: 0px;
  }
}

.app-modal {
  .nav {
    .nav-item {
      img {
        max-width: 120px;
      }
    }
  }
}

.app-main-screen {
  align-items: center;
  justify-content: center;

  .app-main-img {
    img {
      max-height: 200px;
      width: auto !important;
    }
  }
}

.app-alert {
  &.dashboard-modal {
    h3.modal-title {
      text-align: $text-c;
      line-height: $line-height-md !important;
      font-size: $h2-font-size !important;
      font-family: $font-family-nexa-bold;
      letter-spacing: $character-spacing-0-8;
      color: $white;
      text-transform: $text-uppercase;
      max-width: 220px;
    }
  }

  h3.modal-title {
    text-align: $text-c;
    line-height: $line-height-md !important;
    font-size: $h2-font-size !important;
    font-family: $font-family-nexa-bold;
    letter-spacing: $character-spacing-0-8;
    color: $white;
    text-transform: $text-uppercase;
  }
}
