@import '@theme/variables.scss';

.faq-section-box {
  .user-account {
    margin-right: 1.65rem !important;
    margin-left: 0;
    opacity: 0.5;

    &.active {
      opacity: 1;

      h6 {
        font-family: $font-family-poppins-semibold;
      }
    }
  }
}
