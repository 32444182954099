@import '@theme/variables.scss';

.activation-form {
  padding-top: 1.75rem;
  min-height: 27.5rem;

  &.activation-form-toggle {
    min-height: 21.5rem;
  }
}

.activation-toggle-step {
  .custom-switch {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-left: 0;
    .form-check-label {
      font-family: $font-family-nexa-bold;
      font-size: $h4-font-size;
      line-height: $line-height-xs;
      text-transform: $text-uppercase;
      padding-left: 0;
    }
  }
  .toggle-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .custom-dropdown {
      min-width: 15rem;
      max-width: 15rem;
    }
    .text-danger {
      color: $danger;
      font-size: $font-10;
      font-family: $font-family-poppins-regular;
      letter-spacing: $character-spacing-0-3;
      opacity: 1;
      line-height: $line-height-l6;
      margin-top: 0.5rem;
    }
  }
}
.reload-icon {
  max-width: 1rem;
}
