@import '@theme/variables.scss';

.payment-failed,
.payment-sucess {
  p {
    color: $primary !important;
  }
  .custom-width {
    max-width: 357px;
  }
}
.top-up-amount {
  h6 {
    color: $primary;
    font-family: $font-family-poppins-semibold;
  }
  span {
    color: $primary;
    font-family: $font-family-poppins-regular;
  }
}
