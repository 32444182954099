@import '@theme/variables.scss';

.user-profile {
  .user-profile-card {
    .btn-link {
      height: 1.5rem;
      position: relative;
      right: -10px;
    }
    .profile-email {
      text-align: $text-l;
      letter-spacing: $character-spacing-0-42;
      font-size: $font-size-md !important;
      color: $black;
    }
  }
  .item-bill-card {
    p.copytext {
      font-size: $font-size-xs;
    }
    .btn-link {
      height: 1.5rem;
      font-size: $font-size-xs;
    }
  }
  .input-group {
    .input-group-text {
      top: 0.4rem;
    }
  }
  .hotline-card-body {
    .input-group.mb-3 {
      margin-bottom: 0.5rem !important;

      #pswrdForHtLine {
        margin-top: 0 !important;
      }
    }
  }
}
