@import '@theme/variables.scss';
.infoModel {
  h3 {
    color: $white;
    text-align: $text-c;
    font-size: $h2-font-size;
    line-height: $line-height-md;
  }
  p {
    padding-top: 0.75rem !important;
  }
}
.terms-radio {
  p {
    margin-bottom: 0;
    padding-top: 5px;
  }
}
