#tree1_crown {
  stroke-dasharray: 2338.5;
  stroke-dashoffset: 2338.5;
  animation: dash2 6s linear infinite;
}

.st0 {
  fill: none;
  stroke: #153d63;
  stroke-width: 12;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 13.3333;
}

#loader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

#Ebene_1 {
  width: 40%;
  height: 40%;
}

#tree2_crown {
  stroke-dasharray: 2338.5;
  stroke-dashoffset: 2338.5;
  animation: dash2 6s linear 1s infinite;
}

#ray1 {
  stroke-dasharray: 2338.5;
  stroke-dashoffset: 2338.5;
  animation: dash2 6s linear 2.5s infinite;
}

#ray2 {
  stroke-dasharray: 2338.5;
  stroke-dashoffset: 2338.5;
  animation: dash2 6s linear 3s infinite;
}

#ray3 {
  stroke-dasharray: 2338.5;
  stroke-dashoffset: 2338.5;
  animation: dash2 6s linear 3.5s infinite;
}

#ray4 {
  stroke-dasharray: 2338.5;
  stroke-dashoffset: 2338.5;
  animation: dash2 6s linear 4s infinite;
}

#ray5 {
  stroke-dasharray: 2338.5;
  stroke-dashoffset: 2338.5;
  animation: dash2 6s linear 4.5s infinite;
}

@keyframes dash2 {
  100% {
    stroke-dashoffset: 0;
  }
}
