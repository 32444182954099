@import '@theme/variables.scss';

.cancel-contract-left-inner {
  p {
    width: 7.813rem;
    min-width: 7.813rem;
    word-wrap: break-word;
  }
}
.cancel-contract-right {
  textarea {
    background: $white;
    border-radius: 0.375rem;
    height: 7rem;
    overflow-y: auto;
    width: 100%;
    border: none;
    padding: 0.75rem;
    font-size: $font-size-xs;
    font-family: $font-family-poppins-regular;
    color: $grey-200;
    font-style: italic;

    &:focus-visible {
      outline: none;
    }
  }

  .input-mb {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 0.375rem;
    .v2 {
      margin-top: 0 !important;
      border-bottom: none;
      padding-left: 0.375rem !important;
    }
  }
  .contract-text-small {
    font-size: $font-10;
    line-height: $line-height-l;
  }
  textarea {
    text-align: $text-l;
  }
}
.cancel-contract-area {
  .input-mb {
    background: none;
  }
  textarea {
    max-height: 200px;
    resize: none;
    background: $white !important;
    line-height: 1.25rem;
  }
}
