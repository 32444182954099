@import '@theme/variables.scss';

.inside-bonus-center {
  width: 80%;
  margin: auto;
  padding-top: 6rem;

  hr {
    border-color: $blue-500;
    opacity: 0.5;
  }
  .w-60 {
    width: 60%;
    margin: auto;
  }

  .sucess-icon {
    width: 1.9rem;
    height: 1.9rem;
    margin-right: 0;
  }
  .inside-bonus-content-img {
    top: -6rem;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;

    img {
      max-width: 10rem;
    }
  }
}
.inside-bonus-main {
  width: 100% !important;
}
