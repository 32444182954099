@import '@theme/variables.scss';

.chage-password-err-list {
  li {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    font-family: $font-family-poppins-regular;
    font-size: $font-10;
    line-height: $line-height-l6;
    letter-spacing: $character-spacing-0-3;
    color: $grey-200;
    opacity: 1;
    line-height: $line-height-l6;
  }
}
.input-group {
  .input-group-text {
    border: none;
    background: none;
    position: absolute;
    right: 0;
  }
}

.error-icon,
.sucess-icon {
  width: 0.625rem;
  margin-right: 0.313rem;
  vertical-align: top;
  padding-top: 0.188rem;
}
