@import '@theme/variables.scss';

main .custom-dropdown {
  background: $white;
  box-shadow: $custom-dropdown-shadow;
  border-radius: $dropdown-radius;
  display: inline-block;
  padding: $dropdown-padding-x $dropdown-padding-y;
  cursor: pointer;
  min-width: $dropdown-min-width;
  font-family: $font-family-poppins-regular !important;

  .btn {
    text-decoration: none;
    color: $grey-200;
    text-align: $text-l;
    letter-spacing: $character-spacing-0-26;
    font-size: $font-size-xs !important;
    line-height: $line-height-19;
    border: none;
    padding: 0;
    height: auto;
    opacity: 0.8;
    font-family: $font-family-poppins-regular;
    text-transform: inherit;

    &:focus {
      outline: none;
    }

    &.dropdown-toggle {
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &::after {
        content: '';
        background: url('../../../../theme/assets/img/down.svg') no-repeat;
        position: absolute;
        right: 0;
        width: $dropdown-arrow-width;
        height: $dropdown-arrow-height;
        border: 0;
        top: 0.375rem;
      }

      &.show {
        &::after {
          transform: rotateZ(-180deg);
        }
      }
    }
  }
  .dropdown-menu {
    border: none;
    left: 0;
    box-shadow: $custom-dropdown-shadow;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    top: 1.7rem;
    transform: translate(0px, 1.875rem) !important;
    max-width: 15rem;
    max-height: 15rem;
    overflow-y: auto;
    overflow-x: hidden;

    .dropdown-item {
      text-align: $text-l;
      color: $black;
      font-size: $font-size-xs;
      line-height: $line-height-19;

      &:hover {
        background: $blue-300;
        color: $white;
      }
    }
  }
}
