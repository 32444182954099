@import '@theme/variables.scss';

.force-password-change {
  .input-group.mb-3 {
    margin-bottom: 0 !important;
  }

  .input-group-text {
    top: 0 !important;
    .bi {
      padding-top: 0.4rem;
    }
  }
}
