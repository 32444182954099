@import '@theme/variables.scss';

.tariff-detail {
  max-width: 100% !important;
  width: 100% !important;
}
.tariff-sucess,
.tariff-fail {
  width: $modal-width;

  p {
    color: $primary !important;
  }
}
