@import '@theme/variables.scss';

.navbar {
  background: $blue-400;
  opacity: 0.9;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  padding: 0 !important;
}
.nav-link {
  font-family: $font-family-poppins-regular;
  font-size: $font-size-xs !important;
  line-height: $line-height-l8;
  letter-spacing: $character-spacing-0-24;
  color: $primary !important;
  opacity: 1;
}
.header_inner {
  height: 4.125rem;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
  // width: $container-max-widths;

  ul {
    li {
      &:first-child {
        .nav-link {
          padding-left: 0;
        }
      }
      .nav-link {
        padding: 0.3125rem 1.406rem;
        font-family: $font-family-poppins-medium;

        &:hover {
          span {
            border-bottom: 1px solid $primary;
          }
        }
      }
      &.active {
        .nav-link {
          span {
            border-bottom: 1px solid $primary;
          }
        }
      }
    }
  }
}
.logout {
  .btn-link {
    text-decoration: none;
    text-transform: inherit;

    &:hover {
      color: $primary;
    }
  }
  i {
    display: inline-block;
    padding-left: 0.5rem;
  }
  img {
    width: 1rem;
  }
}
