@import '@theme/variables.scss';

.dash-tarif-detail {
  background: $dark-gradient-welcome;
  border-radius: 1rem;
  box-shadow: $card-box-shadow;
  padding: 0.938rem;

  .btn-link {
    color: $blue-300;
    text-transform: capitalize;
    height: auto;

    .bi {
      font-size: $font-10;
      padding-left: 0.438rem;
    }
  }
  h4 {
    text-transform: inherit;
  }
  hr {
    border-color: $white;
    opacity: 0.1;
    margin: 0.5rem 0 1rem 0;
  }
}
.dashboard-top {
  padding: 4.5rem 0 0 0;
}
