@import 'bootstrap/scss/bootstrap.scss';
@import '~bs-stepper/dist/css/bs-stepper.min.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import 'react-circular-progressbar/dist/styles.css';

@import './styles.scss';

@import './variables.scss';
@import './typography.scss';
@import './mixins';

@import '../../src/components/core/Inputs/Button/style.scss';

main {
  &.dashboard-bg {
    background: $bs-gradient-light;
    height: calc(100vh - 142px);
    overflow-y: auto;
  }
}

body .navbar.navbar-expand-lg.navbar-dark {
  background-color: $blue-400 !important;
  height: 4.125rem;
  padding: 0;
}

.container {
  max-width: $container-max-widths;
}

.text-container {
  max-width: $modal-width;

  h2 {
    letter-spacing: $character-spacing-0-4;
  }
}

.primary-background {
  margin-bottom: 20px;
}

// Common Style start
body {
}

h1,
.h1,
h2,
.h2,
h4,
.h4 {
  font-family: $font-family-nexa-bold;
  margin: 0;
  padding: 0;
}

h1 {
  color: $primary;
  font-family: $font-family-nexa-bold;
  font-size: $h1-font-size;
  line-height: $line-height-lg;
  letter-spacing: $character-spacing-1-12;
  text-align: $text-l;
}

h2 {
  font-family: $font-family-nexa-bold;
  font-size: $h2-font-size;
  line-height: $line-height-md;
  color: $primary;
  letter-spacing: $character-spacing-1-6;
  text-align: $text-l;
}

h3 {
  font-family: $font-family-poppins-medium;
  font-size: $h3-font-size;
  line-height: $line-height-sm;
  color: $primary;
  letter-spacing: $character-spacing-0-3;
  text-align: $text-l;
}

h4 {
  font-family: $font-family-nexa-bold;
  font-size: $h4-font-size;
  line-height: $line-height-xs;
  color: $primary;
  letter-spacing: $character-spacing-1-5;
  text-align: $text-l;
  text-transform: $text-uppercase;
}

p {
  font-size: $font-size-sm;
  line-height: $line-height-xs;
  color: $primary;
  letter-spacing: $character-spacing-0-26;
}

// Common Style End

.breadcum {
  font-family: $font-family-poppins-medium;
  font-size: $font-size-xs;
  color: $primary;
  letter-spacing: $character-spacing-0-26;
  line-height: $line-height-19;
}

.headings {
  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 15px;
  }
}

.copytext {
  font-family: $font-family-poppins-regular;
  font-size: $font-size-sm;
  color: $primary;
  letter-spacing: $character-spacing-0-26;
  line-height: $line-height-xs;

  span {
    padding-top: 1.25rem;
    display: block;
  }
}

.copytext-medium {
  font-family: $font-family-poppins-medium;
  font-size: $font-size-sm;
  color: $primary;
  letter-spacing: $character-spacing-0-26;
  line-height: $line-height-xs;

  span {
    padding-top: 1.25rem;
    display: block;
  }
}

.copytext-semibold {
  font-family: $font-family-poppins-semibold;
  font-size: $font-size-sm;
  color: $primary;
  letter-spacing: $character-spacing-0-26;
  line-height: $line-height-xs;

  span {
    padding-top: 1.25rem;
    display: block;
  }
}

.nav-tabs {
  border-color: $white;
}

.dark-gradient {
  background: $dark-gradient;
}

.light-gradient {
  background: $light-gradient;
}

.vh-100 {
  min-height: 100vh;
}

.activation-middle {
  height: calc(100vh - 116px);
}

.top-header {
  box-shadow: $header-top-box-shadow;
  opacity: 1;
  backdrop-filter: blur(10px);
  height: $top-header-height;
  background: $header-gradient;
}

.activation-main {
  min-height: calc(100vh - 0px);
  padding-top: 10.813rem;
  padding-bottom: 2rem;
}

.primary-light-gradient {
  background: $primary-light-gradient;
}

.pt-15 {
  padding-top: 0.938rem;
}

.invalid-feedback {
  color: $danger;
  font-size: $font-10;
  font-family: $font-family-poppins-regular;
  letter-spacing: $character-spacing-0-3;
  opacity: 1;
  line-height: $line-height-l6;
  margin-top: 0.5rem;

  .error-icon {
    width: 0.625rem;
    margin-right: 0.313rem;
    vertical-align: top;
    padding-top: 0.188rem;
  }
}

.valid-feedback {
  font-size: $font-10;
  font-family: $font-family-poppins-regular;
}

.email-varification-password {
  input[type='tel'] {
    background: transparent linear-gradient(148deg, #ffffff 0%, #ffffff80 100%) 0% 0% no-repeat
      padding-box;
    border-radius: 16px;
    opacity: 0.8;
    width: $email-otp-input-width !important;
    height: 64px !important;
    padding: 0 !important;
    text-align: $text-c;
    border: 0;

    font-size: $font-30;
    font-family: $font-family-poppins-regular;
    color: $primary;
    margin-right: 0.75rem !important;

    &.is-invalid {
      border-style: solid;
      border-width: 1px;
      border-color: $danger;
      color: $danger;
    }

    &:focus {
      background: transparent linear-gradient(148deg, #ffffff 0%, #ffffff80 100%) 0% 0% no-repeat
        padding-box;
      outline: none;
    }
  }
}

.logo {
  cursor: pointer;
}

.top-header {
  .step {
    display: none;
  }
}

.custom_dropdown {
  .form-label {
    padding-top: 12px;
  }

  .w-40 {
    width: 40%;
    min-width: 9.375rem;
  }

  .toggle-dropdown {
    max-width: 60%;

    .custom-dropdown {
      min-width: 12.5rem;
      padding-right: 1.25rem;
      max-width: 12.5rem;
    }
  }
}

.card-edit {
  .btn {
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    height: 20px;
    background: none !important;

    img {
      width: 1.2rem;
    }
  }
}

.back-icon {
  img {
    cursor: pointer;
  }
}

.auto-top-amount {
  .form-control {
    padding: 0 !important;
    // text-align: $text-l !important;
  }
}

.address-details {
  .dropdown-menu {
    max-width: 12.5rem !important;
  }
}

.modal-wh {
  max-width: $modal-width;
}

.dark-gradient-welcome {
  background: $dark-gradient-welcome;
}

.CircularProgressbar {
  border-radius: 100%;
  opacity: 1;
  background: $white;
  box-shadow: $progressbar-shadow;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: transparent;
}

.dashboard-slider {
  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }
}

// breadcum
.breadcrumb {
  margin-bottom: 0;

  .breadcrumb-item {
    a, span {
      font-size: $font-9;
      font-family: $font-family-poppins-regular;
      color: $primary;
      letter-spacing: $character-spacing-0-2;
      text-decoration: none;
    }
  }
}

// accordian
.accordion {
  background: none !important;
  padding-bottom: 2rem;

  .accordion-item {
    background: none !important;
    border: none;
    border-bottom: 1px solid $primary-light-100;
    border-radius: 0;

    .accordion-button {
      background: none !important;
      border: 0;
      font-family: $font-family-poppins-medium;
      font-size: $font-size-sm;
      color: $primary;
      letter-spacing: $character-spacing-0-26;
      line-height: $line-height-xs;
      padding: 0.75rem 1.5rem 0.75rem 0;

      &:focus {
        box-shadow: none;
      }

      &::after {
        content: '\F64D';
        background-image: none;
        font-family: bootstrap-icons !important;
        font-size: $font-size-base;

        position: absolute;
        right: 0;
        top: 0.95rem;
      }
    }
  }

  .accordion-body {
    padding: 0.5rem 0;

    p {
      font-family: $font-family-poppins-regular;
    }
  }
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  content: '\F63B' !important;
  background-image: none;
  font-family: bootstrap-icons !important;
  transform: none;
  font-size: $font-18;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0;
}

.dashboard-bg {
  .explainer-full-video {
    .modal-dialog {
      max-width: initial;
    }

    .modal-content {
      padding: 0 !important;
      background: none !important;
      box-shadow: none;
      max-width: $container-max-widths !important;
    }

    .close {
      font-size: $font-10;
      position: absolute;
      z-index: 9;
      right: 0.75rem;
      top: 0.75rem;
      color: $primary;
      opacity: 1;

      &:focus {
        box-shadow: none;
      }
    }

    video {
      border-radius: 0.75rem;
    }
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.3rem;
}

// .alert-warning {
//   background: $warning;
//   padding: 5px;
//   border-color: $warning;
//   color: $primary;
//   font-size: $font-size-xs;
//   line-height: $line-height-19;
//   font-family: $font-family-poppins-regular;
// }
