@import '@theme/variables.scss';

.terms-pdf {
  ul {
    padding-left: 0;
    li {
      list-style: none;
      font-size: $font-size-xs;
      line-height: $line-height-19;
      text-decoration: underline;
      font-family: $font-family-poppins-medium;
      display: flex;
      margin-bottom: 1.5rem;
      align-items: center;

      img {
        max-width: 1.5rem;
        max-height: 1.5rem;
        margin-right: 1rem;
      }
    }
  }
}
