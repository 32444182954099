@import '@theme/variables.scss';

main .card {
  width: $modal-width;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  border: $card-border;
  backdrop-filter: blur(10px);
  background: $card-box-gradient;
  opacity: 1;
  position: relative;
  margin-bottom: 2rem;

  &.tarrif-card {
    background-size: 100%;
    background-position: right bottom;
    background-repeat: no-repeat;

    h2 {
      &.card-title {
        border-bottom: 1px solid $primary-light-100;
        padding-bottom: 1.857rem;
        margin-bottom: 0;
        line-height: $line-height-xs;
      }
    }
    .card-body {
      .tarrif-list {
        margin-top: 1.653rem;
      }
      ul {
        padding-left: 0;

        li {
          margin-bottom: 1rem;
          list-style: none;
          display: flex;
          color: $primary;

          .bi-chevron-right {
            font-size: $font-size-xs;
            padding-right: 0.5rem;
            padding-top: 0.25rem;
          }

          p {
            letter-spacing: 0;
            color: $primary;
            font-size: $font-size-md;
            line-height: $line-height-xs;
            font-family: $font-family-poppins-regular;
            margin-bottom: 0;

            span {
              font-family: $font-family-poppins-semibold;
            }
          }
        }
        margin-bottom: 0;
      }
      hr {
        width: 8rem;
        border-color: $primary-light-100;
        opacity: 1;
      }
    }

    .tarrif-total {
      margin-top: 2rem;
      p {
        span {
          font-family: $font-family-poppins-semibold;
          font-size: $h2-font-size;
          line-height: $line-height-l8;
          letter-spacing: $character-spacing-0-4;
          color: $primary;
        }
      }
    }
    .btn {
      margin: 1.875rem 0;
    }
  }

  .card-body {
    padding: 1.875rem;
  }
}
