@import '@theme/variables.scss';

.help-legal {
  h5 {
    margin-bottom: 0;
  }

  span {
    display: flex;
    align-items: center;
    img {
      max-width: 1.25rem;
      max-height: 1.25rem;
      margin-right: 0.75rem;
    }
  }
}
.advanatges-bg {
  background-repeat: no-repeat;
  background-position: right bottom;
  height: 100%;
  background-size: contain;
}
