@import '@theme/variables.scss';

.app-alert {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 9999;
}

.alert {
  svg {
    font-size: 16px;
    width: 25px;
    height: 25px;
  }
}

.alert-primary {
  svg {
    fill: #20b5ea;
  }
  // background-color: #153d63;
  background: $dark-gradient;
  box-shadow: $btn-box-shadow;
  border-color: $btn-border-color;
  h3 {
    color: white;
    margin: 0;
  }
}

.alert-success {
  svg {
    fill: $success;
  }
  // background-color: #153d63;
  background: transparent linear-gradient(90deg, #1e221f -50%, $success 120%) 0% 0% no-repeat
    padding-box !important;
  box-shadow: 0px 6px 16px rgba(112, 144, 176, 0.4) !important;
  border-color: $success !important;
  h3 {
    color: white;
    margin: 0;
  }
}

.alert-warning {
  svg {
    fill: $warning;
  }
  // background-color: #153d63;
  background: transparent linear-gradient(90deg, #1e221f -50%, $warning 120%) 0% 0% no-repeat
    padding-box !important;
  box-shadow: 0px 6px 16px rgba(112, 144, 176, 0.4) !important;
  border-color: $warning !important;
  h3 {
    color: white;
    margin: 0;
  }
}

.alert-danger {
  svg {
    fill: $danger;
  }
  // background-color: #153d63;
  background: transparent linear-gradient(90deg, #1e221f -50%, $danger 120%) 0% 0% no-repeat
    padding-box !important;
  box-shadow: 0px 6px 16px rgba(112, 144, 176, 0.4) !important;
  border-color: $danger !important;
  h3 {
    color: white;
    margin: 0;
  }
}
